import Vue from 'vue'
export default new Vue({
    methods: {
        enviarNovosUsuarios(novosUser) {
            this.$emit('getNovosUsuarios', novosUser)
        },
        getNovosUsuarios(callback) {
            this.$on('getNovosUsuarios', callback)
        },
        enviarStatusLogin(isLogin, isSessaoExpirada) {
            this.$emit('getStatusLogin', isLogin, isSessaoExpirada)
        },
        getStatusLogin(callback) {
            this.$on('getStatusLogin', callback)
        },
        enviarAddFase() {
            this.$emit('getAddFase')
        },
        getAddFase(callback) {
            this.$on('getAddFase', callback)
        },
        enviarRemoverFases() {
            this.$emit('getRemoverFases')
        },
        getRemoverFases(callback) {
            this.$on('getRemoverFases', callback)
        },
        enviarNovalicitacao() {
            this.$emit('getNovalicitacao')
        },
        getNovalicitacao(callback) {
            this.$on('getNovalicitacao', callback)
        },
        enviarModoEdicao(isModoEdicao) {
            this.$emit('getModoEdicao', isModoEdicao)
        },
        getModoEdicao(callback) {
            this.$on('getModoEdicao', callback)
        },
        enviarModoBkp(isModoBkp) {
            this.$emit('getModoBkp', isModoBkp)
        },
        getModoBkp(callback) {
            this.$on('getModoBkp', callback)
        },
        enviadoProdutosPregao(produtos){
            this.$emit('getTodosprodutosPregao', produtos)
        },
        getProdutosPregao(callback){
            this.$on('getTodosprodutosPregao', callback)
        },
        enviadoValorTotalPregao(produtos){
            this.$emit('getValorTotalPregao', produtos)
        },
        getValorTotalPregao(callback){
            this.$on('getValorTotalPregao', callback)
        },
        enviadoIdPregao(idPregao){
            this.$emit('getIdPregao', idPregao)
        },
        getIdPregao(callback){
            this.$on('getIdPregao', callback)
        },
        enviadoPesquisaCard(pesquisaCard){
            this.$emit('getPesquisaCard', pesquisaCard)
        },
        getPesquisaCard(callback){
            this.$on('getPesquisaCard', callback)
        }
    }
})