<template>
  <v-list v-if="cAtividades.length > 0" dense id="c-views-atividades-sc" color="dprimeira">
    <v-list-item-group v-model="selectAtividades" color="segunda">
      <v-list-item
        class="pl-11 my-2 py-2 c-atividades-ct"
        v-for="(atividade, idAtividade) in cAtividades"
        :key="idAtividade"
      >
        <v-btn
          v-if="atividade.isAnexos"
          color="segunda"
          absolute
          left
          fab
          small
          class="sexta--text ml-n7"
        >
          <v-icon>cloud_download</v-icon>
        </v-btn>
        <v-btn
          v-if="atividade.isComentario"
          color="segunda"
          absolute
          left
          fab
          small
          class="sexta--text ml-n7"
        >
          <v-icon>speaker_notes</v-icon>
        </v-btn>
        <v-btn
          v-if="atividade.isEstado"
          color="segunda"
          absolute
          left
          fab
          small
          class="sexta--text ml-n7"
        >
          <v-icon>published_with_changes</v-icon>
        </v-btn>
        <v-btn
          v-if="atividade.isMudancaFase"
          color="segunda"
          absolute
          left
          fab
          small
          class="sexta--text ml-n7"
        >
          <v-icon>sync_alt</v-icon>
        </v-btn>
        <v-list-item-content>
          <v-list-item-title class="text-body-1 font-weight-black segunda--text ml-n2 pl-3">
            {{atividade.autor}}
            <div class="pa-1 ml-n1">
              <v-chip
                class="c-atividades-data"
                x-small
                color="#e5e5e5"
                text-color="segunda"
              >{{ tempoPassado(atividade.dataHora) }}</v-chip>
            </div>
          </v-list-item-title>
          <v-list-item-title
            v-if="atividade.isComentario"
            class="text-body-2  py-2 pl-3 c-atividade-comentario"
          >{{atividade.comentario}}<div class="py-1"><hr /></div><div>Na {{atividade.fase}}</div> </v-list-item-title>
          <v-list-item-subtitle v-if="atividade.isEstado" class="c-atividades-acao">
            <v-expansion-panels accordion class="c-atividades-views">
              <v-expansion-panel v-for="(item,i) in 1" :key="i">
                <v-expansion-panel-header
                  color="segunda"
                  class="sexta--text text-body-2"
                >Mostrar Diferença</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p class="mt-5">Agora</p>
                  <div class="ma-2 mt-n3">{{atividade.estado.atual}}</div>
                  <p class="mt-5">Antes</p>
                  <div class="ma-2 mt-n3 mb-n0">{{atividade.estado.anterior}}</div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-list-item-subtitle>

          <v-list-item-subtitle v-if="atividade.isAnexos" class="c-atividades-acao">
            <v-expansion-panels accordion class="c-atividades-views px-0 mx-0">
              <v-expansion-panel v-for="(item,i) in 1" :key="i" class="px-0 mx-0">
                <v-expansion-panel-header
                  color="segunda"
                  class="sexta--text text-body-2"
                >Mostrar Diferença de Anexos</v-expansion-panel-header>
                <v-expansion-panel-content class="mx-0 px-0 important">
                  <p class="mt-5 pb-0 mb-1">Agora</p>
                  <v-list dense id="c-views-anexo-historico" class="mx-0 px-0 py-0 my-0">
                    <v-list-item-group v-model="cViewsSelecFile" color="segunda">
                      <v-list-item
                        v-for="(anexoHistorico, idAnexoHistorico) in atividade.estado.atual"
                        :key="idAnexoHistorico"
                        class="mx-0 px-0"
                      >
                        <v-list-item-icon class="mx-0 px-0">
                          <v-icon small v-text="anexoHistorico.icone"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="anexoHistorico.nomeArquivo"
                            @click="abrirArquivoAtividade(anexoHistorico.caminho, 'verLink')"
                          />
                          <v-list-item-subtitle
                            class="d-inline-flex flex-wrap c-atividades-anexos-sbtitle"
                          >
                            <v-chip
                              color="segunda"
                              text-color="white"
                              x-small
                            >{{anexoHistorico.nomeFase}}</v-chip>
                            <v-chip
                              color="segunda"
                              text-color="white"
                              x-small
                            >{{anexoHistorico.nomeCampo}}</v-chip>
                            <v-chip
                              color="segunda"
                              text-color="white"
                              x-small
                            >{{anexoHistorico.inseridoEm}}</v-chip>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action class="mx-0 px-0 pr-2">
                          <v-list-item-action-text
                            class="mx-0 px-0"
                            @click="abrirArquivoAtividade(anexoHistorico.caminho, 'verLink')"
                          >
                            <v-icon small color="segunda" right>open_in_new</v-icon>
                          </v-list-item-action-text>
                        </v-list-item-action>
                        <v-list-item-action class="mx-0 px-0">
                          <v-list-item-action-text
                            class="mx-0 px-0"
                            @click="downloadArquivoAtividade(anexoHistorico.caminho, anexoHistorico.tipo, anexoHistorico.nomeArquivo, 'download')"
                          >
                            <v-icon small color="segunda">downloading</v-icon>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>

                  <p class="mt-5 pb-0 mb-1">Antes</p>
                  <v-list
                    v-if="atividade.estado.anterior"
                    dense
                    id="c-views-anexo-historico"
                    class="mx-0 px-0 py-0 my-0"
                  >
                    <v-list-item-group v-model="viewsSelecFileAtividade" color="segunda">
                      <v-list-item
                        v-for="(anexoHistorico, idAnexoHistorico) in atividade.estado.anterior"
                        :key="idAnexoHistorico"
                        class="mx-0 px-0"
                      >
                        <v-list-item-icon class="mx-0 px-0">
                          <v-icon small v-text="anexoHistorico.icone"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="anexoHistorico.nomeArquivo"
                            @click="abrirArquivoAtividade(anexoHistorico.caminho, 'verLink')"
                          />
                          <v-list-item-subtitle v-if="anexoHistorico[0] !== ''">
                            <v-chip
                              color="segunda"
                              text-color="white"
                              x-small
                            >{{anexoHistorico.nomeFase}}</v-chip>
                            <v-chip
                              color="segunda"
                              text-color="white"
                              x-small
                            >{{anexoHistorico.nomeCampo}}</v-chip>
                            <v-chip
                              color="segunda"
                              text-color="white"
                              x-small
                            >{{anexoHistorico.inseridoEm}}</v-chip>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action class="mx-0 px-0 pr-2">
                          <v-list-item-action-text
                            class="mx-0 px-0"
                            @click="abrirArquivoAtividade(anexoHistorico.caminho, 'verLink')"
                          >
                            <v-icon small color="segunda" right>open_in_new</v-icon>
                          </v-list-item-action-text>
                        </v-list-item-action>
                        <v-list-item-action class="mx-0 px-0">
                          <v-list-item-action-text
                            class="mx-0 px-0"
                            @click="downloadArquivoAtividade(anexoHistorico.caminho, anexoHistorico.tipo, anexoHistorico.nomeArquivo, 'download')"
                          >
                            <v-icon small color="segunda">downloading</v-icon>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <p v-else>SEM ANEXOS</p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "listaAtividades",
  props: {
    atv: Array,
    dialogoEventAtv: Boolean
  },
  data: function() {
    return {
      cAtividades: this.atv,
      selectAtividades: [1],
      cViewsSelecFile: [1],
      viewsSelecFileAtividade: [1],
    };
  },
  watch: {
    dialogoEventAtv(el) {
      if (el) {
        this.cAtividades = this.atv;
      } else {
        this.cAtividades = [];
      }
    }
  },
  computed: {
    tempoPassado() {
      return (tempo, id) => {
        let dataAgora = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        ).toISOString();

        let dataAtual = this.$moment(dataAgora);
        let dataHoraComentario = this.$moment(tempo);
        return dataHoraComentario.from(dataAtual);
      };
    }
  },
  methods: {
    downloadArquivoAtividade(caminho, tipo, nome, action) {
      let baixarArquivo = {
        caminho,
        tipo,
        nome,
        action,
      }
      this.$emit("downloadArquivoAtividade", baixarArquivo);
    },
    abrirArquivoAtividade(caminho, action) {
      let verArquivos = {
        caminho,
        action
      }
      this.$emit("abrirArquivoAtividade", verArquivos);
    },
  },
};
</script>

<style>

</style>