<template>
    <v-list dense color="dprimeira">
      <v-list-item-group v-model="selechistorico" color="segunda">
        <v-list-item
          v-for="(storico, idStorico) in historicoFase"
          :key="idStorico"
        >
          <v-list-item-icon>
            <v-icon color="segunda">move_up</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              class="text-body-1 font-weight-black segunda--text mt-2"
            >Licitação movida de {{storico.faseAnterior}} para {{storico.nomeFase}}</v-list-item-title>
            <v-list-item-subtitle class="py-2 pl-2 c-atividade-comentario">Por {{storico.autor}}</v-list-item-subtitle>
            <v-divider />
            <div
              class="pt-2"
            >{{storico.dataHora | moment("add", "3 h", "calendar")}}</div>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
</template>

<script>
export default {
  name: "historico",
  props: {
    dialogoEventH: Boolean,
    listah: Array
  },
  data: function() {
    return {
      historicoFase: this.listah,
      selechistorico: [1]
    };
  },
  watch: {
    dialogoEventH(el) {
      if (el) {
        this.historicoFase = this.listah;
      } else {
        this.historicoFase = [];
      }
    }
  },
};
</script>

<style>
</style>