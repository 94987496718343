<template>
  <v-container class="c-content__login fill-height">
    <v-row class="d-flex flex-column justify-center align-center" >  
         <v-btn color="#943634" class="white--text" elevation="16" fab loading x-large></v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
name: 'Loader',
}
</script>
<style>
</style>