<template>
  <v-app class="s-app">
    <div id="app">
      <Header :dadosUser="dadosUser" />
      <Loader v-if="validatingToken" />
      <Content
        v-else
        :usuarioLogado="usuarioLogado"
        :sessaoExpirada="sessaoExpirada"
        :dadosUser="dadosUser"
      />
    </div>
  </v-app>
</template>

<script>
import axios from "axios";
import { urlApi, showError, userKey } from "@/global";
import barramento from "@/barramento";
import Header from "./components/tm/Header";
import Content from "./components/tm/Content";
import Loader from "./components/tm/Loader";

export default {
  name: "App",
  components: { Header, Content, Loader },
  data: function() {
    return {
      validatingToken: true,
      usuarioLogado: false,
      sessaoExpirada: false,
      dadosUser: {}
    };
  },
  methods: {
    async validateToken() {
      this.validatingToken = true;

      const json = localStorage.getItem(userKey);
      const userData = JSON.parse(json);
      this.usuarioLogado = false;
      this.dadosUser = {};

      if (!userData) {
        this.validatingToken = false;
        this.usuarioLogado = false;
        this.dadosUser = {};
        return;
      }
      
      const res = await axios.post(`${urlApi}/validateToken`, userData);

      if (res.data) {
        axios.defaults.headers.common["Authorization"] = `bearer ${userData.token}`;
        this.usuarioLogado = true;
        this.dadosUser = userData;

        if (res.data.admin === false) {
          localStorage.removeItem(userKey);
          this.sessaoExpirada = true;
          this.usuarioLogado = false;
          this.dadosUser = {};
          return;
        }
      } else {
        localStorage.removeItem(userKey);
        this.usuarioLogado = false;
        this.dadosUser = {};
      }

      this.validatingToken = false;
    }
  },
  created() {
    this.validateToken();
    barramento.getStatusLogin((isTrue, sessaoExpirada) => {
      if (isTrue) {
        this.validateToken();
        this.sessaoExpirada = sessaoExpirada;
      }
      isTrue = false;
    });
  }
};
</script>

<style>
* {
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px;
  font-weight: 500;
  box-sizing: border-box;
}
.text-h5 {
  word-break: break-word;
}
.u_largura-90 {
  width: 135px;
}
.u-largura-135 {
  width: 135px;
}
.u-width-fixa__115 {
  width: 115px;
}
.u-width-fixa__220 {
  width: 220px;
}
.u-width-fixa__320 {
  width: 320px;
}
.u-width-fixa__520 {
  width: 520px;
}
.s-app {
  background: transparent !important;
}
body {
  margin: 0px;
  background: #943634;
}

/*ScrolBar*/
::-webkit-scrollbar {
  width: 1px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  border: none;
  background: rgb(54 52 148 / 38%);
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #363494;
}
::-webkit-scrollbar-thumb:active {
  background: #363494;
}
::-webkit-scrollbar-track {
  background: none;
  border: none;
}
::-webkit-scrollbar-track:hover {
  background: #dddddd;
}
::-webkit-scrollbar-track:active {
  background: #dddddd;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
.u-hide {
  display: none;
}
.bg-teste {
  background: chartreuse;
}
.bg-teste2 {
  background: rgb(255, 174, 0);
}
.bg-teste3 {
  background: rgb(4, 116, 145);
}
.bg-teste4 {
  background: rgb(0, 95, 24);
}
.alerta.error {
  background: #ff3131 !important;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  min-height: 100%;
  position: fixed;
}
.c-header__topo{
  width: 100vw!important;
  height: 63px
}
.c-header__col{
  height: 63px;
}
.c-content-wrapper {
    height: calc(100vh - 63px)!important;
    width: 100vw;
}
.c-fases-wrapper {
    display: initial;
}
.c-scroll-horizontal {
    height: 100%;
    overflow-y: hidden;
}
.c-col__fase__gestor {
    min-width: 280px;
    max-width: 280px;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}
.c-col__fase__gestor::-webkit-scrollbar {
    width: 2px;
}
.c-col__fase__gestor::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}
.c-col__fase__gestor::-webkit-scrollbar-corner {
    background: none;
}
.c-col__fase__gestor::-webkit-scrollbar-thumb {
    border: 4px solid #f0f8ff;
    width: 20px!important;
    height: 20px!important;
}
.c-col__fase__gestor:hover::-webkit-scrollbar-thumb {
    border: 4px solid #363494;
    width: 20px!important;
    height: 20px!important;
}
.c-col__fase__gestor::-webkit-scrollbar-track {
    background: #f0f8ff;
    border: none;
}
</style>