import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/lib/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { pt },
        current: 'pt',
      },
      theme: {
        themes: {
          light: {
            primeira: '#943634',
            segunda: '#363494',
            terceira: '#f1f1f1',
            quarta: '#e7eefb',
            quinta: '#f0f8ff',
            sexta: 'f2f2f2',
            oitava: 'bc4b43',
            nona: '827c82',
            decima: '0baf2e',
            dprimeira: 'f9f8f8',
            accent: '#8c9eff',
            error: '#ff3131',
            anchor: '#131111'
          },
        },
      },
});
