<template>
  <v-dialog
    persistent
    v-model="dialogCadasTroPregao"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="btnDialogo" :abrirDialogo="{evento: on, atributos: attrs}"></slot>
    </template>

    <template v-slot:default="dialogCadasTroPregao">
      <v-card>
        <v-toolbar color="segunda" dark>
          <v-toolbar-title>Cadastrar Pregão</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn class="mx-2" icon>
              <v-icon dark large @click="dialogCadasTroPregao.value = false">cancel</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <cadastroPregao
            :passIdPregao="idPregaoAtualizado"
            :systemPregao="sistemPregao"
            :orgaosCadastrados="orgaosCadastrados"
            :produtosCadastrados="produtosCadastrados"
          ></cadastroPregao>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn id="js-modal-pregao__close" text @click="dialogCadasTroPregao.value = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import axios from "axios";
import cadastroPregao from "./cadastroPregao";
import { rutPregao, showError } from "@/global";
export default {
  name: "modalCadastroPregao",
  components: { cadastroPregao },
  props: {
    sistemPregao: {},
    passIdPregao: String
  },
  data: function() {
    return {
      dialogCadasTroPregao: false,
      orgaosCadastrados: [],
      produtosCadastrados: [],
      idPregaoAtualizado: ''
    };
  },
  watch: {
    passIdPregao(novoIdPregao, antigoIdPregao){
      this.idPregaoAtualizado = novoIdPregao
    },
    dialogCadasTroPregao(val) {
      if (val) this.getOrgao();
      this.getProduto();
    }
  },
  methods: {
    fecharModal() {
      this.dialogCadasTroPregao = false;
    },
    getOrgao() {
      axios
        .get(`${rutPregao}/orgao`, {
          headers: {
            Authorization: `bearer ${this.sistemPregao.sistemPregao.acesso.data.token}`
          }
        })
        .then(res => {
          res.data.filter((orgao, index, arr) => {
            this.orgaosCadastrados.push(arr[index].nomeOrgao);
          });
        })
        .catch(showError);
    },
    getProduto() {
      axios
        .get(`${rutPregao}/produto`, {
          headers: {
            Authorization: `bearer ${this.sistemPregao.sistemPregao.acesso.data.token}`
          }
        })
        .then(res => {
          this.produtosCadastrados = res.data;
        })
        .catch(showError);
    }
  },
  mounted() {
  },
  created() {}
};
</script>

<style>
</style>