<template>
  <div id="c-content" class="c-content c-content__principal">
    <div class="quinta c-content-wrapper" v-if="usuarioLogado">
      <v-slide-y-reverse-transition appear>
        <fases :dadosUser="dadosUser"></fases>
      </v-slide-y-reverse-transition>
    </div>

    <v-container v-else class="c-content__login fill-height">
      <v-fade-transition appear>
        <v-row class="d-flex flex-column justify-center align-center">
          <v-col>
            <login :sessaoExpirada="sessaoExpirada" />
          </v-col>
        </v-row>
      </v-fade-transition>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { urlApi, showError, userKey } from "@/global";
import { dragscroll } from "vue-dragscroll";
import barramento from "@/barramento";
import login from "../usuario/login";
import fases from "../layout-kanban/fases";

export default {
  directives: {
    dragscroll
  },
  name: "Content",
  components: { login, fases },
  props: {
    usuarioLogado: Boolean,
    sessaoExpirada: Boolean,
    dadosUser: Object
  }
};
</script>
<style scoped>
.c-content__login {
    position: fixed;
    left: 0px;
    right: 0px;
}
</style>