<template>
  <v-container fluid class="quarta ma-0 pa-0 c-header__topo" v-if="dadosUser.name !== undefined">
    <v-slide-y-transition appear>
      <v-col
        cols="12"
        lg="12"
        md="12"
        sm="12"
        xs="12"
        class="d-flex flex-row flex-nowrap c-header__col"
      >
        <div class="c-logo__col">
          <v-img
            class="c-header__logo"
            src="@/assets/logo-nutrimix-distribuidora.svg"
            contain
            max-height="auto"
            max-width="150"
          ></v-img>
        </div>
        <div class="ml-3 c-config__col align-self-center mr-auto">
          <v-btn
            elevation="3"
            color="quarta"
            fab
            small
            rounded
            class="segunda--text mr-3"
            @click="openNovaLicitacao"
          >
            <v-icon color="segunda">create_new_folder</v-icon>
          </v-btn>

          <v-btn
            v-if="modoEdicao"
            elevation="3"
            color="quarta"
            fab
            small
            rounded
            class="segunda--text mr-3"
            @click="addFases()"
          >
            <v-icon color="segunda">post_add</v-icon>
          </v-btn>
          <v-btn
            v-if="modoEdicao"
            small
            outlined
            rounded
            class="segunda--text mr-3"
            @click="disRemoverFases"
          >
            <v-icon left>remove_circle_outline</v-icon>Remover Fase
          </v-btn>
        </div>
        <v-switch
          dense
          small
          class="align-self-center c-header__switch"
          v-model="modoEdicao"
          label="MODO EDIÇÃO"
          color="segunda"
        ></v-switch>
        <v-switch
          dense
          small
          class="ml-5 align-self-center c-header__switch"
          v-model="modoBkp"
          label="BACKUPS"
          color="segunda"
        ></v-switch>
        <span class="ml-4 c-buscar-card" >
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-text-field
              v-model="cardsCadastrados"
              label="Procurar licitação"
              placeholder="Procurar licitação"
              color="segunda"
              background-color="quarta"
              dense
              rounded
              solo
              flat
              hide-details
              :class="`elevation-${hover ? 4 : 1}`"
              class="c-color_text__segunda"
              clearable
              @click:clear="resetConsultaCard"
            >
            <template v-slot:append>
              <v-icon color="segunda">content_paste_search</v-icon>
            </template>
            </v-text-field>
          </template>
        </v-hover>
        </span>

        <v-menu right bottom transition="slide-y-transition">
          <template v-slot:activator="{ on }">
            <v-btn text class="segunda--text mt-n2" x-large v-on="on">
              {{ dadosUser.name }}
              <v-icon
                v-if="dadosUser.admin"
                class="pl-2 mr-2"
                right
                dark
                large
                color="segunda"
              >mdi-shield-account</v-icon>
              <v-icon v-else class="pl-2 mr-2" right dark large color="white">person</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar color="#943634" class="mb-2">
                  <v-icon dark>mdi-account-circle</v-icon>
                </v-avatar>
                <h3>{{ dadosUser.name }}</h3>
                <p class="text-caption mt-1">{{ dadosUser.email }}</p>
                <v-divider class="my-3"></v-divider>

                <modalGestaoUsuario />

                <v-divider class="my-3"></v-divider>
                <v-btn depressed rounded text color="#943634" @click="logout">
                  <v-icon left dark color="#943634">logout</v-icon>Sair
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </v-col>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import axios from "axios";
import { urlApi, showError, userKey } from "@/global";
import modalGestaoUsuario from "../usuario/modalGestaoUsuario";
import barramento from "@/barramento";
export default {
  name: "Header",
  components: { modalGestaoUsuario },
  props: {
    dadosUser: {}
  },
  data: function() {
    return {
      cardsCadastrados: "",
      user: {
        initials: "JD",
        fullName: this.dadosUser.name,
        email: this.dadosUser.email,
        openMenu: true
      },
      modoEdicao: false,
      modoBkp: false
    };
  },
  watch: {
    modoEdicao(el) {
      barramento.enviarModoEdicao(el);
    },
    modoBkp(el) {
      barramento.enviarModoBkp(el);
    },
    cardsCadastrados(strNova) {
      barramento.enviadoPesquisaCard(strNova);
    }
  },
  methods: {
    resetConsultaCard(){
      barramento.enviadoPesquisaCard('');
    },
    openNovaLicitacao() {
      barramento.enviarNovalicitacao();
    },
    addFases() {
      barramento.enviarAddFase();
    },
    disRemoverFases() {
      barramento.enviarRemoverFases();
    },
    logout() {
      localStorage.removeItem(userKey);
      barramento.enviarStatusLogin(true, false);
    }
  },
};
</script>

<style>
/* .c-header {
  grid-area: c-header;
  background: transparent;
  padding: 15px 25px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
} */
.c-color_text__segunda input{
  color: #363494!important;
}
.c-header {
  background: transparent;
  padding: 15px 25px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.c-header__switch label {
  font-size: 14px;
  color: #363494 !important;
}
</style>